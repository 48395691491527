/*-------------------ОСНОВНЫЕ----------------------------*/
$init      : transparent;
$transition: .3s ease-in;

$brand-primary-600: #4429B4;
$brand-primary-400: #6E3FF3;
$brand-primary-200: #9773FF;
$gradient-pink: linear-gradient(90deg, #F7C182 0%, #F47BCE 100%);
$gradient-green: linear-gradient(90deg, #79E7EE 0%, #AAEB8B 100%);
$gradient-violet: linear-gradient(83.5deg, #C532EA 5.07%, #4F37E7 94.84%);

/*-------------------ТИПОГРАФИКА----------------------------*/

$font: "Inter", Arial, sans-serif;
$font-heading: "Heading Pro", Arial, sans-serif;
$type-dark-primary: #FFFFFF;
$type-dark-secondary: rgba(255, 255, 255, 0.8);
$type-dark-tertiary: rgba(255, 255, 255, 0.4);
$type-dark-disable: rgba(255, 255, 255, 0.1);

$type-primary: #16112D;
$type-secondary: #4B4662;
$type-tertiary: #78748A;
$type-disable: #CCCAD7;


/*-------------------UI----------------------------*/
$ui-border: 1px solid rgba(255, 255, 255, 0.2);

$ui-surface: #120F1C;
$ui-surface-2: #231F35;
$ui-surface-3: #322D4A;
$ui-border-color: rgba(255, 255, 255, 0.2);
$ui-success-600: #00AA25;
$ui-success-400: #35BB42;
$ui-warning-600: #FF7918;
$ui-warning-400: #FFA31A;
$ui-error-600: #E3172F;
$ui-error-400: #FF3743;
$ui-overlay: rgba(0, 0, 0, 0.5);

/*-------------------ДОПОЛНИТЕЛЬНО----------------------------*/
$add-red-400: #EE0000;
$add-red-600: #C40000;

$add-blue-600: #2CA8AF;
$add-blue-400: #46C5CC;
$add-green-600: #63A431;
$add-green-400: #86CB50;
$add-yellow-600: #ED8E00;
$add-yellow-400: #FFBA36;
